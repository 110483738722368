import Topbar from "../../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../../Layouts/Layout"

import "../styles.scss"
import TaxForProduct from "../../../domain/superAdTaxManageMent/TaxForProduct"

const index = () => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout selectedPage={<TaxForProduct />} />
    </div>
  )
}

export default index
